import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as reduxUserActions from "../../redux/actions/user";
import {LoadingComponent} from "../../components/loading_component";

class OauthContainer extends React.Component {
    state = {}

    componentDidUpdate() {}

    static getDerivedStateFromProps(props, state) {
        let user_values = props.location.hash.substring(1).split("&");
        user_values.forEach((value) => {
            let key = value.split("=")[0];
            let val = value.split("=")[1];
            localStorage.setItem("jwt_" + key, val);
        });

        props.userActions.setToken(localStorage.getItem("jwt_access_token"));
        props.history.push('/');

        return null;
    }

    render() {
        return (
            <LoadingComponent/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(reduxUserActions, dispatch),
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OauthContainer);
import {SET_USER, SET_USER_TOKEN} from '../constants/user';

const initialState = {data: null, access_token: null};

export default function user(state = initialState, action) {

    switch (action.type) {
        case SET_USER:
            return {
                ...state, data: {
                    ...state.data, ...action.payload
                }
            };

        case SET_USER_TOKEN:
            return {
                ...state, access_token: action.payload
            };

        default:
            return state;
    }
}

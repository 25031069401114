import React from "react";
import moment from 'moment/src/moment';

export const BookmarkComponent = ({bookmark}) => {
    return (
        <div className="d-flex justify-content-end mb-3" id={bookmark.id}>
            <div className="d-flex flex-column justify-content-end">
                <div className="bg-inverse-dark text-right text-white p-2 rounded">{bookmark.text}</div>
                <span className="text-muted font-italic text-right" style={{fontSize: ".7rem"}}>{moment(bookmark.created_at).format("DD.MM.YYYY HH:mm")}</span>
            </div>
        </div>
    )

};
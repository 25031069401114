import React from "react";
import {connect} from "react-redux";
import ClientOAuth2 from "client-oauth2";
import {OAUTH_CLIENT_ID, API_HOST, WEB_AUTH_HOST} from "../../constants";
import {LoadingComponent} from "../../components/loading_component";

class SignInContainer extends React.Component {

    state = {
        nibouOAuth2: new ClientOAuth2({
            clientId: OAUTH_CLIENT_ID,
            accessTokenUri: API_HOST + '/oauth/token',
            authorizationUri: API_HOST + '/oauth/authorize',
            redirectUri: WEB_AUTH_HOST + '/auth/redirect',
        })
    };

    static getDerivedStateFromProps(props, state) {
        if (props.userState != null) {
            props.history.push('/');
        } else {
            window.location.href = state.nibouOAuth2.token.getUri();
        }
        return null;
    }


    render() {
        return (
            <LoadingComponent/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);
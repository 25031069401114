import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as reduxUserActions from "../../redux/actions/user";
import {LoadingComponent} from "../../components/loading_component";

class SignOutContainer extends React.Component {
    componentDidMount() {
        const {userActions} = this.props;
        userActions.setUser(null);
        localStorage.removeItem("jwt_access_token");
        window.location.href = '/users/sign_out';
    }

    render() {
        return (
            <LoadingComponent/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(reduxUserActions, dispatch),
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOutContainer);
// #region imports
import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from 'history'
import rootReducer from '../reducers'

export const history = createBrowserHistory();

// createStore : enhancer
const enhancer =  compose(
    applyMiddleware(thunkMiddleware, routerMiddleware(history))
);

// export default =  "redux store"
export default function configureStore(initialState) {
    return createStore(rootReducer(history), initialState, enhancer);
}

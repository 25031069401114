import {SET_NEW_MESSAGE, SET_NEW_ROOM, SET_SESSION_END, SET_SESSION_TIMEOUT} from '../constants/notification';

const initialState = {
    new_message: null,
    new_room: null,
    end_session: null,
    session_timeout: null
};

export default function notification(state = initialState, action) {

    switch (action.type) {
        case SET_NEW_MESSAGE:
            return {
                ...state, new_message: action.payload
            };

        case SET_NEW_ROOM:
            return {
                ...state, new_room: action.payload
            };

        case SET_SESSION_END:
            return {
                ...state, end_session: action.payload
            };

        case SET_SESSION_TIMEOUT:
            return {
                ...state, session_timeout: action.payload
            };

        default:
            return state;
    }
}

import {SET_USER, SET_USER_TOKEN} from '../constants/user';

export function setUser(userData) {
    return {
        type: SET_USER,
        payload: userData
    }
}

export function setToken(userData) {
    return {
        type: SET_USER_TOKEN,
        payload: userData
    }
}
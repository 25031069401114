import {SET_ROOM, CLOSE_ROOM} from '../constants/room';

const initialState = {};

export default function room(state = initialState, action) {

    switch (action.type) {
        case SET_ROOM:
            return {
                ...state, ...action.payload
            };

        case CLOSE_ROOM:
            return {};

        default:
            return state;
    }
}

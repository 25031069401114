import React from "react";
import {connect} from "react-redux";
import {ChatRoomTopComponent} from "../../components/chat/rooms_component";
import * as reduxRoomActions from "../../redux/actions/room";
import {bindActionCreators} from "redux";

class TopRoomContainer extends React.Component {

    onCloseHandler = (e) => {
        e.preventDefault();
        this.props.roomActions.closeRoom();
    };

    render() {
        const {userState, roomState} = this.props;

        if (roomState.id) {
            return (
                <ChatRoomTopComponent current_user={userState} room={roomState} onClose={this.onCloseHandler}/>
            )
        } else {
            return ('')
        }
    }
}

const mapStateToProps = (state) => ({
    userState: state.user.data,
    roomState: state.room,
});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
    roomActions: bindActionCreators(reduxRoomActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopRoomContainer);


import React, {Component} from "react";
import {Route, Redirect} from "react-router-dom"
import axios from 'axios';

export const WEB_AUTH_HOST = process.env.WEB_AUTH_HOST;
export const API_HOST = process.env.API_HOST;
export const WEBSOCKET_HOST = "wss://api.nibouapp.com/cable";//process.env.WEBSOCKET_HOST;
export const OAUTH_CLIENT_ID = process.env.OAUTH_CLIENT_ID;
// export const HEADERS = {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
// };

export const API = () => {
    const token = localStorage.getItem("jwt_access_token");

    if (token == null){
        window.location.href = '/sign_in';
    }

    return axios.create({
        baseURL: process.env.API_HOST + '/v1',
        headers: {
            Authorization: "Bearer " + token,
            'content-type': 'multipart/form-data',
            'Accept': 'application/json',
        },
        validateStatus: function (status) {
            if (status == 401) {
                window.location.href = '/sign_out';
            }

            return status >= 200 && status < 300; // default
        },
    })
};

export const ProtectedRoute = ({component: Component, isAuthenticated, ...rest}) => (
    <Route {...rest} render={(props) => (
        isAuthenticated === true ?
            <Component {...props} /> : <Redirect to={{pathname: '/sign_in', state: {from: props.location}}}/>
    )}/>
);
import {SET_ROOM, CLOSE_ROOM} from '../constants/room';

export function setRoom(roomData) {
    return {
        type: SET_ROOM,
        payload: roomData
    }
}

export function closeRoom() {
    return {
        type: CLOSE_ROOM,
        payload: {}
    }
}
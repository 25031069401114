import React from "react";
import PropTypes from 'prop-types';
import {Field, Form, Formik} from "formik";
import Dropzone from 'react-dropzone'
import {faPaperPlane, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class ChatSendMessageFormComponent extends React.Component {

    state = {
        images: [],
        new_message: ""
    };

    render() {
        const {onSend} = this.props;
        const {images} = this.state;

        return (
            <Dropzone accept='image/*' onDrop={acceptedFiles => {
                this.setState({images: acceptedFiles});
            }} className='dropzone ' activeClassName='active-dropzone'>

                {({getRootProps, getInputProps}) => (
                    <Formik
                        enableReinitialize={true}
                        initialValues={this.state}
                        onSubmit={(values, actions) => {
                            onSend(values);
                            actions.setValues({new_message: "", images: []});
                            this.setState({images: [], new_message: ""});
                        }}
                        render={({handleChange}) => (
                            <Form>
                                <div {...getRootProps()} className="border-top mt-2">
                                    <input {...getInputProps()} />

                                    {images.length > 0 ?
                                        <p className="text-muted">Selected {images.length} files ({images.map((image, key) => (<span key={key}>{image.name}&nbsp;</span>))})</p>
                                     : <p className="text-muted btn btn-link"><FontAwesomeIcon icon={faPaperclip} size={"2x"} className="mr-3" /> Drag 'n' drop some files here, or click to select
                                        files</p>}
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <Field type="text" name="new_message"
                                               onChange={(e) => {
                                                   handleChange(e);
                                                   this.setState({new_message: e.target.value})
                                               }}
                                               className="form-control" placeholder="Type message here..."/>
                                        <div className="input-group-append">
                                            <button className="btn btn-sm btn-primary" type="submit">
                                                <FontAwesomeIcon icon={faPaperPlane} className="mr-3" />
                                                Send
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Form>

                        )}
                    />
                )}
            </Dropzone>
        )
    }
}

ChatSendMessageFormComponent.propTypes = {
    room_id: PropTypes.string,
    onSend: PropTypes.func
};

import React from "react";
import {connect} from "react-redux";
import * as reduModalActions from "../../redux/actions/bookmark";
import {bindActionCreators} from "redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {API} from "../../constants";

class BookmarksContainer extends React.Component {

    state = {
        bookmarkText: "",
        loading: true,

    };

    static getDerivedStateFromProps(props, state) {
        const {modalState} = props;

        if (modalState && modalState.message && modalState.message.id && state.bookmarkText.length === 0) {
            return {
                bookmarkText: modalState.message.text,
                loading: false
            }
        }

        if (modalState && !modalState.message ) {
            return {
                bookmarkText: "",
                loading: true
            }
        }

        return null;
    }

    handleOnSaveBookmark = () => {
        const text = this.state.bookmarkText;
        this.setState((state) => ({...state, loading: true}));

        const {modalState, modalActions} = this.props;
        let formData = new FormData();
        formData.append('text', text);
        formData.append('message_id', modalState.message.id);
        API().post(`/chat/message/${modalState.room.id}/bookmarks`, formData).then(() => {
            modalActions.hideModal();
        }).catch(console.error);
    };

    handleOnChangeBookmarkText = (e) => {
        e.persist();

        if (e.target && e.target.value) {
            this.setState((state) => ({...state, bookmarkText: e.target.value}))
        }
    };

    handleCloseModal = () => {
        this.setState({bookmarkText: "", loading: true});
        this.props.modalActions.hideModal();
    };

    render() {
        const {bookmarkText, loading} = this.state;
        const {modalState} = this.props;

        const isOpened = modalState.message ? true : false;

        return (
            <Modal isOpen={isOpened}>

                <ModalHeader toggle={this.handleCloseModal}>Add new bookmark</ModalHeader>
                <ModalBody className="d-flex flex-column justify-content-center align-items-center">
                    {loading ? (<div className="spinner-border text-secondary">
                        <span className="sr-only">Loading...</span>
                    </div>) : (<textarea className="form-control" value={bookmarkText}
                                                                onChange={this.handleOnChangeBookmarkText}></textarea>)}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.handleOnSaveBookmark} color="success">Save</Button>
                    <Button color="secondary" onClick={this.handleCloseModal}>Cancel</Button>
                </ModalFooter>

            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        modalState: state.bookmark
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        modalActions: bindActionCreators(reduModalActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BookmarksContainer);
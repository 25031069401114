import React from "react";
import {connect} from "react-redux";
import {LoadingComponent} from "../../components/loading_component";
import {ChatRoomComponent} from "../../components/chat/rooms_component";
import {API} from "../../constants";
import * as reduxRoomActions from "../../redux/actions/room";
import {bindActionCreators} from "redux";
import {faInbox} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class RoomsContainer extends React.Component {

    state = {
        rooms: [],
        room_loaded: false
    };

    componentWillMount() {
        API().get('/chat/rooms').then(rooms => {
            this.setState(state => ({...state, rooms: rooms.data.data, room_loaded: true}));
        }).catch(err => {
            console.log(err)
        })
    }

    componentWillReceiveProps({roomState, newRoomState}) {
        setTimeout(() => {
            API().get('/chat/rooms').then(rooms => {
                this.setState(state => ({...state, rooms: rooms.data.data, room_loaded: true}));
            }).catch(err => {
                console.log(err)
            })
        }, 500);
    }

    handleChangeRoom = (room_id) => {
        const {roomActions} = this.props;
        API().get(`/chat/rooms/${room_id}`).then((room) => {
            roomActions.setRoom(room.data.data);
        }).catch(err => console.error(err));
    };

    render() {
        const {userState, newMessageState, roomState} = this.props;
        const {rooms, room_loaded} = this.state;

        if (room_loaded == false)
            return <LoadingComponent/>;

        if (rooms.length == 0) {
            return (
                <div className="d-flex flex-column justify-content-center align-items-center text-muted">
                    <p>No conversations to show...</p>
                    <FontAwesomeIcon icon={faInbox} size={"2x"} className="mt-2 text-muted"/>
                </div>
            )
        }

        return (
            <div className="d-flex flex-column" style={{overflowX: "hidden"}}>
                {
                    rooms.map((room, key) => {
                        return (
                            <ChatRoomComponent key={key}
                                handleChangeRoom={this.handleChangeRoom}
                                room={room}
                                current_room={roomState}
                                current_user={userState}
                                latest_message={newMessageState}
                            />
                        )
                    })
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userState: state.user.data,
    roomState: state.room,
    newMessageState: state.notification.new_message,
    newRoomState: state.notification.new_room,

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
    roomActions: bindActionCreators(reduxRoomActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomsContainer);


import React from "react";
import {connect} from "react-redux";
import {SideMenuComponent} from "../components/top_menu_component";

class TopMenuContainer extends React.Component {
    render() {
        const {userState} = this.props;
        if (userState == null)
            return "";

        return <SideMenuComponent current_user={userState}/>
    }
}

const mapStateToProps = (state) => ({
    userState: state.user.data,
});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopMenuContainer);


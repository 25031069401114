import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router'
import configureStore, {history} from "./redux/store/configureStore";

import AppContainer from "./AppContainer";

export default class Root extends Component {
    render() {
        return (
            <Provider store={configureStore()}>
                <ConnectedRouter onUpdate={() => window.scrollTo(0, 0)} history={history}>
                    <AppContainer/>
                </ConnectedRouter>
            </Provider>
        );
    }
}

import React from "react";
import {connect} from "react-redux";
import {LoadingComponent} from "../../components/loading_component";
import {ActionCableConsumer} from "react-actioncable-provider";
import {ChatMessageComponent} from "../../components/chat/message_component";
import * as reduxRoomActions from "../../redux/actions/room";
import * as reduxNotificationActions from "../../redux/actions/notification";
import {bindActionCreators} from "redux";
import {API} from "../../constants";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BookmarksContainer from "./bookmarks_container";
import * as reduModalActions from "../../redux/actions/bookmark";
import {BookmarkComponent} from "../../components/chat/bookmark_component";

class MessagesContainer extends React.Component {

    state = {
        messages: [],
        bookmarks: [],
        bookmarkedMessages: [],
        message_loaded: false,
        roomDeleted: false,
    };

    componentWillReceiveProps({roomState, newMessageState, endSessionState, roomActions, userState}) {
        if (endSessionState != null && (roomState.id == endSessionState.room.data.id)) {
            roomActions.closeRoom();
        }

        // if (roomState.attributes.is_private === false) {
        //     const customer = roomState.attributes.users.filter(user => user.data.id != userState.data.id)[0];
        //     API().get(`/chat/message/${customer.data.id}/bookmarks`).then((messages) => {
        //         console.log("bk", messages);

        //         const bookmarks = messages.data.data.map((message) => {
        //             return {id: message.id, ...message.attributes}
        //         }).filter(function (el) {
        //             return el != null;
        //         });

        //         const bookmarkedMessage = messages.data.data.map((message) => {
        //             return message.relationships.message.data.id
        //         });

        //         this.setState({bookmarks: bookmarks, bookmarkedMessages: bookmarkedMessage})
        //     });
        // }

        API().get(`/chat/message/${roomState.id}`).then((messages) => {
            messages = messages.data.data.map((message) => {
                if (message.attributes.text != "END_SESSION")
                    return {id: message.id, ...message.attributes}
            }).filter(function (el) {
                return el != null;
            });
            this.setState({messages: messages, message_loaded: true})
        });
    }

    handleReceived = (message) => {
        if (message.data.attributes.text != "END_SESSION") {
            let messages = this.state.messages;
            messages.push({
                id: message.data.id,
                ...message.data.attributes
            });

            this.setState(state => {
                return {
                    ...state,
                    messages: messages,
                    message_loaded: true
                }
            });
        }
    };

    handleBookmarkClick = (message, room) => {
        this.props.modalActions.showModal({message: message, room: room});
    };

    render() {
        const {userState, roomState} = this.props;
        const {messages, message_loaded, roomDeleted, bookmarks, bookmarkedMessages} = this.state;

        if (roomState.id == undefined || roomDeleted) {
            return <div className="vh-chat d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-muted">Select a conversation</h2>
                <FontAwesomeIcon icon={faComments} size={"5x"} className="mt-2 text-muted"/>
            </div>
        }

        if (roomState.attributes.active == false) {
            return <div className="vh-chat d-flex flex-column justify-content-center align-items-center">
                <h2 className="text-muted">This room is closed!</h2>
                <FontAwesomeIcon icon={faComments} size={"5x"} className="mt-2 text-muted"/>
            </div>
        }

        if (message_loaded == false) {
            return <LoadingComponent/>;
        }

        return (
            <ActionCableConsumer channel={{channel: 'ChatChannel', rid: roomState.id}} onReceived={this.handleReceived}>
                <BookmarksContainer/>
                {(bookmarks.map((msg, key) => (<BookmarkComponent bookmark={msg} key={key}/>)))}

                {(messages.map((msg, key) => (
                    <ChatMessageComponent message={msg} key={key} current_user={userState} room={roomState}
                                          onBookmarkClick={this.handleBookmarkClick} bookmarkedMessages={bookmarkedMessages}/>)))}
            </ActionCableConsumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data,
        roomState: state.room,
        newMessageState: state.notification.new_message,
        endSessionState: state.notification.end_session,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        roomActions: bindActionCreators(reduxRoomActions, dispatch),
        notificationActions: bindActionCreators(reduxNotificationActions, dispatch),
        modalActions: bindActionCreators(reduModalActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesContainer);
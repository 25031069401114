import React, {useState, useEffect, useRef} from "react";
import {LoadingComponent} from "../loading_component";
import moment from 'moment/src/moment';
import momentDurationFormatSetup from 'moment-duration-format/lib/moment-duration-format'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHourglass, faWindowClose} from '@fortawesome/free-solid-svg-icons'

export const ChatRoomComponent = ({handleChangeRoom, room, current_user, latest_message, current_room}) => {
    if (current_user == undefined) {
        return <LoadingComponent/>;
    }
    let last_message = (room.attributes.last_message ? room.attributes.last_message.data.attributes.text : '...');
    // let counter = 0;
    if (latest_message && latest_message.room.data.id == room.id) {
        last_message = latest_message.message.data.attributes.text;
    }
    // if (latest_message && (latest_message.room.data.id = !current_room.id)) {
    //     counter++;
    // }
    const customer = room.attributes.users.filter(user => user.data.id != current_user.data.id)[0];

    let classes = "d-flex justify-content-start align-items-center mt-3 p-3 rounded room-item";

    if (room.id == current_room.id) {
        classes += ' active-room'
    }

    return (
        <div onClick={(e) => {
            e.preventDefault();
            handleChangeRoom(room.id)
        }} className={classes}>
            <span className="pro-pic mr-3">
                <img height={60} src={customer.data.attributes.avatar.w220.url} className="rounded" alt=""/>
            </span>
            <div className="user">
                <p className="u-name">{customer.data.attributes.username}</p>
                <p className="u-designation text-muted"
                   style={{overflow: 'hidden', textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{last_message}</p>
            </div>
        </div>
    )
};

export const ChatRoomTopComponent = ({room, current_user, onClose}) => {
    // momentDurationFormatSetup(moment);

    if (room == undefined) {
        return <LoadingComponent/>;
    }

    // const [timerDuration, setTimerDuration] = useState(null);
    const [currentRoom, setCurrentRoom] = useState(null);

    if (currentRoom == null || (currentRoom.id != room.id)) {
        setCurrentRoom(room);
        // setTimerDuration(room.attributes.duration)
    }

    const expertises = room.attributes.expertises.map(exp => exp.data.attributes.title);
    const customer = room.attributes.users.filter(user => user.data.id != current_user.data.id)[0];
    // let timer_duration = moment.duration(timerDuration, 'seconds').format("mm:ss");
    // const latestTimerDuration = useRef(timerDuration);
    // let timer;

    // useEffect(() => {
    //     timer = setInterval(() => {
    //         latestTimerDuration.current = timerDuration;
    //         timer_duration = moment.duration(latestTimerDuration.current, 'seconds').format("mm:ss");
    //         setTimerDuration(timerDuration + 1);
    //     }, 1000);
    //
    //     return () => {
    //         clearInterval(timer);
    //     }
    // }, [timerDuration]);

    return (
        <div className="border-bottom p-2 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-start">
                <button type="button" onClick={onClose} className="btn btn-link" title="Close the room window">
                    <FontAwesomeIcon icon={faWindowClose} size={"2x"} className="text-danger"/>
                </button>
                <img height={50} src={customer.data.attributes.avatar.w220.url} className="rounded mr-3" alt=""/>
                <div>
                    <p className="card-text">{customer.data.attributes.username}</p>
                    <p className="card-text text-muted"
                       style={{
                           overflow: 'hidden',
                           textOverflow: "ellipsis",
                           whiteSpace: "nowrap"
                       }}>{expertises.join(", ")}</p>
                </div>
            </div>

            <div className="">
                {/*<FontAwesomeIcon icon={faHourglass} className="mr-2 text-danger"/>{timer_duration}*/}
            </div>
        </div>
    )
};
import {SET_NEW_MESSAGE, SET_NEW_ROOM, SET_SESSION_END, SET_SESSION_TIMEOUT} from '../constants/notification';

export function setNewMessage(messageData) {
    return {
        type: SET_NEW_MESSAGE,
        payload: messageData
    }
}

export function setNewRoom(messageData) {
    return {
        type: SET_NEW_ROOM,
        payload: messageData
    }
}

export function setSessionEnd(messageData) {
    return {
        type: SET_SESSION_END,
        payload: messageData
    }
}

export function setSessionTimeout(messageData) {
    return {
        type: SET_SESSION_TIMEOUT,
        payload: messageData
    }
}

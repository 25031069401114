import {SHOW_MODAL_BOOKMARK, HIDE_MODAL_BOOKMARK} from '../constants/bookmark';

export function showModal(messageData) {
    return {
        type: SHOW_MODAL_BOOKMARK,
        payload: messageData
    }
}

export function hideModal() {
    return {
        type: HIDE_MODAL_BOOKMARK,
        payload: {}
    }
}
import React from "react";
import moment from 'moment/src/moment';
import {faComments, faBookmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ChatMessageComponent = ({message, current_user, room,  onBookmarkClick, bookmarkedMessages}) => {
    let images = [];
    if (message.images[0]) {
        images = message.images[0].data.attributes.images.map(image => ({sm: image['w220']['url'], lg: image['url']}))
    }

    let isBookmarked = bookmarkedMessages.filter(message_id => message_id === message.id).length > 0;

    const bookmarkButton = isBookmarked === false ? (
        <button type="button" className="btn btn-icon" onClick={() => onBookmarkClick(message, room)}>
            <FontAwesomeIcon icon={faBookmark} className="text-muted" size={"2x"}/>
        </button>
    ) : (
        <span className="btn btn-icon">
            <FontAwesomeIcon icon={faBookmark} size={"2x"}/>
        </span>
    );

    if (current_user.data.id == message.from_user_id) {
        return (
            <div className="d-flex justify-content-end mb-3" id={message.id}>
                {
                    message.text && bookmarkButton
                }
                <div className="d-flex flex-column justify-content-end">
                    {images.map((image, key) => (<a href={image.lg} target="_blank" key={key}><img src={image.sm}/></a>))}
                    { message.text ? <div className="bg-inverse-dark text-right text-white p-2 rounded">{message.text}</div> : '' }
                    <span className="text-muted font-italic text-right" style={{fontSize: ".7rem"}}>{moment(message.created_at).format("DD.MM.YYYY HH:mm")}</span>
                </div>
            </div>
        )
    } else {
        return (
            <div className="d-flex justify-content-start mb-3" id={message.id}>
                <div className="d-flex flex-column justify-content-start">
                    {images.map((image, key) => (<a href={image.lg} target="_blank" key={key}><img src={image.sm}/></a>))}
                    { message.text ? <div className="bg-dark text-white p-2 rounded">{message.text}</div> : '' }
                    <span className="text-muted font-italic text-left" style={{fontSize: ".7rem"}}>{moment(message.created_at).format("DD.MM.YYYY HH:mm")}</span>
                </div>
                {
                    message.text && bookmarkButton
                }
            </div>
        )
    }
};
import React from "react";
import {Link} from "react-router-dom";
import logo from "../images/logo.png"
import {faComments, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const SideMenuComponent = ({current_user}) => (
    <div className="vh-100 bg-dark d-flex flex-column justify-content-between align-items-start" style={{minWidth: "90px"}}>
        <div className="mt-2">
            <img src={logo} width={70} alt="logo 2" />

            <div className="text-center ml-1 mt-4">
                <img src={current_user.data.attributes.avatar.url} width={50} className="rounded" alt="profile"/>
            </div>
        </div>

        <div className="text-center ml-3 mb-4">
            <Link to={"/sign_out"} className="text-white">
                <FontAwesomeIcon icon={faSignOutAlt} size={"2x"} className="text-white"/>
                <p>Logout</p>
            </Link>
        </div>
    </div>

);
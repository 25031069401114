import {SHOW_MODAL_BOOKMARK, HIDE_MODAL_BOOKMARK} from '../constants/bookmark';

const initialState = {};

export default function notification(state = initialState, action) {

    switch (action.type) {
        case SHOW_MODAL_BOOKMARK:
            return action.payload;

        case HIDE_MODAL_BOOKMARK:
            return action.payload;

        default:
            return state;
    }
}

import React from "react";
import {connect} from "react-redux";
import * as reduxRoomActions from "../../redux/actions/room";
import * as reduxNotificationActions from "../../redux/actions/notification";
import {bindActionCreators} from "redux";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class NotificationsContainer extends React.Component {

    handleOnClick = () => {
        const {roomActions, notificationActions, notificationState} = this.props;
        const room = notificationState.room.data;

        roomActions.setRoom(room);
        notificationActions.setSessionTimeout(null);
    };

    render() {
        const {notificationState, userState} = this.props;
        if (notificationState == null) {
            return "";
        }
        const customer = notificationState.room.data.attributes.users.filter(user => user.data.id != userState.data.id)[0];

        return (
            <Modal isOpen={true}>
                <ModalHeader className="d-flex justify-content-center align-items-center">Delayed Response</ModalHeader>
                <ModalBody className="d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faExclamationTriangle} size={"3x"}/>
                    <h3 className="mt-4">{customer.data.attributes.username} has been waiting for
                        over {notificationState.timeout} minute(s) for a response</h3>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center align-items-center">
                    <Button size="lg" onClick={this.handleOnClick} color="success">Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data,
        notificationState: state.notification.session_timeout,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        roomActions: bindActionCreators(reduxRoomActions, dispatch),
        notificationActions: bindActionCreators(reduxNotificationActions, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Route, Switch} from "react-router-dom";
import DashBoardContainer from "./containers/dashboard_container";
import SignInContainer from "./containers/users/sign_in_conainer";
import SignOutContainer from "./containers/users/sign_out_conainer";
import OauthContainer from "./containers/users/oauth_container";
import {ProtectedRoute} from "./constants";
import * as reduxUserActions from "./redux/actions/user";
import SideMenuContainer from "./containers/top_menu_container";

class AppContainer extends React.Component {

    render() {
        const {userToken} = this.props;
        return (
            <div className="container-scroller">
                <div className="d-flex justify-content-start">
                    {userToken !== null ? <SideMenuContainer/> : ''}
                    <div className="" style={{width: "calc(100% - 100px)"}}>
                        <Switch>
                            <ProtectedRoute exact path="/" isAuthenticated={userToken !== null} component={DashBoardContainer}/>

                            <Route exact path="/sign_in" component={SignInContainer}/>
                            <Route exact path="/sign_out" component={SignOutContainer}/>
                            <Route exact path="/auth/redirect" component={OauthContainer}/>

                            {/*<Route component={PageNotFound}/>*/}
                        </Switch>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data,
        userToken: state.user.access_token
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(reduxUserActions, dispatch),
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);



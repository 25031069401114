import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import user from './user'
import room from './room'
import notification from './notification'
import bookmark from './bookmark'

const appReducers = {
    user, room, notification, bookmark
};

export default (history) => combineReducers({
    ...appReducers,
    router: connectRouter(history)
})

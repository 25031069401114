import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ActionCableConsumer, ActionCableProvider} from 'react-actioncable-provider';
import {API, WEBSOCKET_HOST} from "../constants";
import {LoadingComponent} from "../components/loading_component";
import MessagesContainer from "./chat/messages_container";
import * as reduxUserActions from "../redux/actions/user";
import * as reduxNotificationActions from "../redux/actions/notification";
import RoomsContainer from "./chat/rooms_container";
import ScrollToBottom from "react-scroll-to-bottom";
import TopRoomContainer from "./chat/top_room_container";
import BottomRoomContainer from "./chat/bottom_room_container";
import NotificationsContainer from "./chat/notifications_container";

class DashBoardContainer extends React.Component {

    state = {
        user: null,
        user_loaded: false
    };

    componentWillMount() {
        const {userActions} = this.props;

        API().get('/users/me').then(user => {
            this.setState(state => {
                return {...state, user: user.data, user_loaded: true}
            }, () => {
                userActions.setUser(user.data);
            });
        }).catch(err => {
            console.log(err)
        });
    }

    handleReceived = (e) => {
        const {notificationActions} = this.props;

        if (e.action == "new_message") {
            notificationActions.setNewMessage(e);
        }

        if (e.action == "new_chat_room") {
            notificationActions.setNewRoom(e);
        }

        if (e.action == "session_end") {
            notificationActions.setSessionEnd(e);
        }

        if (e.action == "session_timeout") {
            notificationActions.setSessionTimeout(e);
        }

        console.log('handleReceived', e);
    };

    handleConnected = (e) => {
        console.log("Cable connected", e);
    };

    handleRejected = (e) => {
        console.log("Cable handleRejected", e);
    };

    handleDisconnected = (e) => {
        console.log("Cable handleDisconnected", e);
    };

    render() {
        const {access_token} = this.props;
        const {user_loaded} = this.state;

        if (user_loaded === true) {
            return (
                <ActionCableProvider url={`${WEBSOCKET_HOST}?access_token=${access_token}`}>
                    <ActionCableConsumer
                        channel={{channel: 'UserNotificationChannel'}}
                        onReceived={this.handleReceived}
                        onConnected={this.handleConnected}
                        onRejected={this.handleRejected}
                        onDisconnected={this.handleDisconnected}
                    >
                        <NotificationsContainer />
                        <div className="row align-self-stretch vh-100 p-2" style={{backgroundColor: "#edeef2"}}>
                            <div className="card pt-4 pb-4 col-3 m-2 overflow-auto shadow-sm bg-inverse-light" style={{height: "calc(100vh - 40px)"}}>
                                <RoomsContainer/>
                            </div>
                            <div className="col p-2">
                                <TopRoomContainer/>
                                <ScrollToBottom className={"overflow-auto vh-chat"}>
                                    <MessagesContainer/>
                                </ScrollToBottom>
                                <BottomRoomContainer/>
                            </div>
                        </div>

                    </ActionCableConsumer>
                </ActionCableProvider>
            )
        }

        return (<LoadingComponent/>)
    }
}

const mapStateToProps = (state) => {
    return {
        userState: state.user.data,
        access_token: state.user.access_token
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators(reduxUserActions, dispatch),
        notificationActions: bindActionCreators(reduxNotificationActions, dispatch),
        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardContainer);

import React from "react";
import {connect} from "react-redux";
import ChatSendMessageFormComponent from "../../components/chat/send_message_form_component";
import {API} from "../../constants";

class BottomRoomContainer extends React.Component {

    handleSendMessage = (values) => {
        const {roomState} = this.props;
        let formData = new FormData();
        if (values.new_message && values.new_message.length > 0)
            formData.append('text', values.new_message);
        for (let i = 0; i < values.images.length; i++)
            formData.append('images[]', values.images[i]);
        API().post(`/chat/message/${roomState.id}`, formData).then().catch(console.error);
    };

    render() {
        const {roomState} = this.props;

        if (roomState.id) {
            return (
                <ChatSendMessageFormComponent room_id={roomState.id} onSend={this.handleSendMessage}/>
            )
        } else {
            return ('')
        }
    }
}

const mapStateToProps = (state) => ({
    roomState: state.room,
});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BottomRoomContainer);

